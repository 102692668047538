import * as React from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { StaticImage } from 'gatsby-plugin-image'

import Page from '../elements/Page.js'

import ContainerWithBg from '../helpers/ContainerWithBg.js'
import Container from '../helpers/Container.js'
import NaMidiaItem from '../components/NaMidiaItem.js'
import { Pagination } from '../components/Pagination.js'

export default function NaMidiaPage(props) {
  const { currentPage, naMediaPostsNumPages } = props.pageContext

  const seoSchema =
    props.data.wp?.seo?.contentTypes?.post?.schema?.raw ?? undefined

  return (
    <Page>
      <Seo
        title={
          currentPage === 1
            ? 'Na Mídia Comunicação Integrada'
            : `Na Mídia Comunicação Integrada - página ${currentPage}`
        }
        postSchema={seoSchema ? JSON.parse(seoSchema) : undefined}
      />
      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-consultoria-hero"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r feature-image"
      >
        <h1 className="h1-egg mb-0 z1">Na Mídia</h1>
      </ContainerWithBg>

      <ContainerWithBg
        className="container-xl"
        classBgName="bg-color-purple-3 p-r"
        noDisplay
      >
        <div className="py-4 py-lg-8">
          <div className="col-size-720">
            <p className="fs-body-large bold c-grey tac mb-0">
              Nosso time de consultores é reconhecido como autoridade e fonte
              segura nas áreas de Planejamento de Comunicação Integrada,
              Mapeamento de Fluxos em Comunicação, e Riscos Reputacionais,
              concedendo entrevistas e escrevendo artigos para os principais
              veículos de comunicação do país.
            </p>
          </div>
        </div>
      </ContainerWithBg>

      <Container className="py-4 py-lg-8 sep-y bg-color-grey-2">
        <div className="container">
          <h2 className="h2 c-purple tac pb-3">
            Confira algumas reportagens e artigos
          </h2>
          <div className="row justify-content-center">
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-globo.png"
                alt="Tv Globo"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-veja.png"
                alt="Revista Veja"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-futura.png"
                alt="Canal Futura"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-canal-tech.png"
                alt="Canal Tech"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-jornal-comercio.png"
                alt="Jornal do Comércio"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-propmark.png"
                alt="Propmark"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-adnews.png"
                alt="Adnews"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-comunique-se.png"
                alt="Comunique-se"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-aberj.png"
                alt="Aberje"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage
                src="../../images/logo-media-mundo-marketing.png"
                alt="Mundo do Marketing"
              />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage src="../../images/logo-media-ufrj.png" alt="UFRJ" />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage src="../../images/logo-media-fgv.png" alt="FGV" />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage src="../../images/logo-media-usp.png" alt="USP" />
            </div>
            <div className="col-6 col-sm-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <StaticImage src="../../images/logo-media-r7.png" alt="R7" />
            </div>
          </div>
        </div>
      </Container>

      <ContainerWithBg
        classBgName="bg-color-purple-4 pb-4r"
        className="container-xg"
        noDisplay="true"
      >
        <div className="container-fluid py-8">
          <h2 className="c-purple mb-4 col-size-820 tac">
            Veja todos os conteúdos
          </h2>

          {props.data.allWpNaMidia.nodes.map((node) => (
            <NaMidiaItem
              local={node.naMidiaFields.comunicacaoMidiaLocal}
              texto={node.naMidiaFields.comunicacaoMidiaTexto}
              tipo={node.naMidiaFields.comunicacaoMidiaTipo}
              link={node.naMidiaFields.comunicacaoMidiaUrl}
              key={node.id}
            />
          ))}

          <Pagination
            postsNumPages={naMediaPostsNumPages}
            currentPage={currentPage}
            base="/na-midia/"
          />
        </div>
      </ContainerWithBg>
    </Page>
  )
}

export const naMidiaListQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpNaMidia(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        naMidiaFields {
          comunicacaoMidiaLocal
          comunicacaoMidiaTexto
          comunicacaoMidiaTipo
          comunicacaoMidiaUrl
        }
      }
    }
  }
`
