import * as React from "react"
import Icon from "../elements/Icon"

export default function NaMidiaItem({ local, link, texto, tipo }) {
  return (
    <div className="bg-color-white rounded p-3 mb-4 col-size-820 d-flex">
      <div className="icon me-4 d-flex align-content-center align-items-center">
        <Icon
          wrapperClass="d-flex flex-column justify-content-center align-items-center"
          type={tipo}
        />
      </div>
      <div className="link">
        <a href={link} className="h4 c-purple" target="_blank" rel="noreferrer">
          <strong>{local}</strong>: {texto}</a>
      </div>
    </div>
  )
}